import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import CryptoJS from "crypto-js"; // Import CryptoJS
import "./AddMassager.css";
import ToasterS from "../../Customer Panel/Toaster";
import ToasterF from "../../Customer Panel/ToasterF";
import api from "../../../api";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string()
  .required("Name is required")
  .matches(/^[a-zA-Z\s]*$/,"Invalid Name"),

  contact: Yup.string()
    .required("Contact is required")
    .matches(/^\d+$/, "Contact must be a number")
    .min(10, "Contact must be at least 10 digits")
    .max(12, "Contact must be at most 12 digits"),
  email: Yup.string()
    .email("Invalid email format")
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,"Invalid email Format")
    .required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  username: Yup.string().required("Username is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  address: Yup.string().required("Address is required"),
  age: Yup.number()
    .required("Age is required")
    .positive("Age must be a positive number")
    .integer("Age must be an integer")
    .max(120, "Age must be at most 120"),
});

// Function to hash password
const hashPassword = (password) => {
  return CryptoJS.SHA256(password).toString(); // Hash the password using SHA-256
};

const AddMassager = ({ show, handleClose, onAddMassager }) => {
  const initialValues = {
    name: "",
    contact: "",
    email: "",
    gender: "",
    username: "",
    password: "",
    address: "",
    age: "",
    status: "Pending",
  };

  const [toastMessage, setToastMessage] = useState("");
  const [showToastFail, setShowToastFail] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleChange = (e, setFieldValue) => {
    const { name, value } = e.target;

    let sanitizedValue = value;

    // Check for the specific field to apply filtering
    if (name === "name") {
      sanitizedValue = value.replace(/[^a-zA-Z\s]/g, ""); // For name
    } else if (name === "contact" || name === "age") {
      sanitizedValue = value.replace(/[^0-9]/g, ""); // For contact and age
    } 

    setFieldValue(name, sanitizedValue);
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      values.password = hashPassword(values.password);
      const response = await api.post(
        "/add_massager.php",
        values
      );


      if (response.data.status === "success") {
        setToastMessage("Massager Registered Successfully");
        setShowToast(true);
        setTimeout(() => {
          onAddMassager();

          setShowToast(false);
          handleClose();
        }, 3000);
        resetForm();
      } else if(response.data.status === "error") {
        setToastMessage(response.data.message);
        setShowToastFail(true);
        setTimeout(() => {
          setShowToastFail(false);
        }, 2000);
      }
    } catch (error) {
      setToastMessage(error.message || "Error adding massager");
      setShowToastFail(true);
      setTimeout(() => {
        setShowToastFail(false);
      }, 2000);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      {/* Toaster components for success and failure notifications */}
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />

      {/* Modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto">Massager Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Formik form wrapped inside the modal */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="Massager_Registraction_form mt-4">
                {/* Name Field */}
                <div className="form-group">
                  <label htmlFor="name" className="fw-bold">
                    Name: <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    id="name"
                    type="text"
                    name="name"
                    className="form-control"
                    onChange={(e) => handleChange(e, setFieldValue)}
                  />
                  <ErrorMessage
                    name="name"
                    component="span"
                    className="error"
                  />
                </div>

                {/* Contact and Email in one row */}
                <div className="form-group-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="contact" className="fw-bold">
                      Contact: <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="tel"
                      id="contact"
                      name="contact"
                      maxLength="10"
                      className="form-control"
                      onChange={(e) => handleChange(e, setFieldValue)}
                    />
                    <ErrorMessage
                      name="contact"
                      component="span"
                      className="error"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="email" className="fw-bold">
                      Email: <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      id="email"
                      type="email"
                      name="email"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="email"
                      component="span"
                      className="error"
                    />
                  </div>
                </div>

                {/* Gender and Age in one row */}
                <div className="form-group-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="gender" className="fw-bold">
                      Gender: <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      id="gender"
                      as="select"
                      name="gender"
                      className="form-control"
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Field>
                    <ErrorMessage
                      name="gender"
                      component="span"
                      className="error"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="age" className="fw-bold">
                      Age: <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      id="age"
                      type="text"
                      name="age"
                      className="form-control"
                      onChange={(e) => handleChange(e, setFieldValue)}
                      maxLength={3} // Set maximum length to 3
                      inputMode="numeric"
                    />
                    <ErrorMessage
                      name="age"
                      component="span"
                      className="error"
                    />
                  </div>
                </div>

                {/* Username and Password in one row */}
                <div className="form-group-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="username" className="fw-bold">
                      Username: <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      id="username"
                      type="text"
                      name="username"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="username"
                      component="span"
                      className="error"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="password" className="fw-bold">
                      Password: <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      id="password"
                      type="password"
                      name="password"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="error"
                    />
                  </div>
                </div>

                {/* Address Field */}
                <div className="form-group">
                  <label htmlFor="address" className="fw-bold">
                    Address: <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    id="address"
                    type="text"
                    name="address"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="address"
                    component="span"
                    className="error"
                  />
                </div>

                {/* Submit Button */}
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary mt-2"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Registering..." : "Register"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddMassager;
